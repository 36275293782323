@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.novo-logo-img {
  width: 190px;
  height: 23px;
}

.univ-logo-img {
  width: 37px;
}

.avatar {
  width: 44px;
  height: 44px;
}

.coverimg {
  background-image: url(/static/media/user-cover.825ef773.jpeg);
  background-size: cover;
  background-position: center center;
  height: 226px;

  border: 1px solid;
  position: relative;
}
.usermodal {
  width: 60%;
}
.usermodal .useravatar {
  width: 140px;
  height: 140px;
  bottom: -70px;
  margin-left: 90px;
}
.usermodal .item p {
  color: rgba(51, 51, 51, 0.6);
}

/* .neu-box::placeholder {
  color: #7e7e7e;
  font-weight: 600;
}
.color-darkbule {
  color: #383874;
}

.border-blue {
  border: 2px solid #0ea4e7;
}
.border-grey {
  border: 2px solid #c4c4c4;
}
div.neu-box {
  color: #7e7e7e;
  transition: all 0.2s;
}

div.neu-box:hover {
  color: #383873;
} */

div.neu-box {
  background: #f9fafd;
  box-shadow: inset -5px -5px 30px rgba(242, 242, 242, 0.8),
    inset 5px 5px 10px rgba(232, 232, 232, 0.8);
  color: #7e7e7e;
  height: 76px;
  font-size: 16px;
  border-radius: 12px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
}

div.neu-icon {
  background: #ffffff;
  box-shadow: -5px -5px 20px #ffffff, 18px 18px 30px #e4e4e4,
    inset 18px 18px 30px #f3f3f3, inset -5px -5px 10px #ffffff;
  border-radius: 12px;
  width: 44px;
  height: 44px;
}

div.neu-box input {
  position: absolute;
  top: 0;

  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}

.logo {
  width: 200px;
}
.login-left::before {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  content: "";
  right: -50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 85%;
}

.login-left::after {
  position: absolute;
  content: "OR";
  font-weight: 700 !important;
  font-size: 16px;
  background-color: #f9fafd;
  color: #90959f;
  right: -80px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
  padding: 5px 20px;
}

.login-btn {
  background: #0ea4e7;
  box-shadow: inset 6px 6px 30px #009fe7, inset -10px -10px 30px #77d5ff !important;
  transition: 0.5s;
}

.selection {
  color: #000;
}

.searchbar {
  color: rgb(123, 121, 125);
}

.card {
  border: 2px solid #e2e9ea;
  box-shadow: 5px 10px 50px rgba(0, 67, 96, 0.15);
  border-radius: 15px;
}

.home-icon {
  color: #0da4e7;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 1s infinite;
          animation: shimmer 1s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.ant-picker-dropdown {
  top: 0;
  left: 0;
}
.ant-picker.datepicker {
  visibility: hidden;
  height: 0px !important;
}
.bounce-left {
  -webkit-animation: bounce-left 1.5s infinite both;
  animation: bounce-left 1.5s infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-7 12:16:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
    transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
    transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
    transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
    transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
    transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
    transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.timepopover-panel {
  padding: 0px;
}

.add-line::after {
  content: "";
  border: 1px solid #e2e9ea;
  position: absolute;
  top: 0;
  right: 30px;
  height: 80%;
  -webkit-transform: translateY(15%);
          transform: translateY(15%);
}

td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
  color: #000;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid rgba(15, 164, 231, 0.5);
  background-color: rgba(15, 164, 231, 0.5);

  /* z-index: -10; */
}

.ant-picker-body {
  padding: 18px !important;
}
.ant-picker-month-panel
  .ant-picker-content
  tbody
  > tr:not(.ant-picker-content tbody > tr:last-child) {
  border-bottom: 1px solid #e2e9ea;
}

.ant-picker-year-panel
  .ant-picker-content
  tbody
  > tr:not(.ant-picker-content tbody > tr:last-child) {
  border-bottom: 1px solid #e2e9ea;
}

.ant-picker-month-panel .datepicker-month .ant-picker-content th,
.ant-picker-content td:first-child {
  border-right: 1px solid #e2e9ea;
}
.ant-picker-month-panel .ant-picker-content th,
.ant-picker-content td:nth-child(2) {
  border-right: 1px solid #e2e9ea;
}

.overlay {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.count-badge {
  background-color: #0fa4e7;
}

.count-badge-loading {
  background-color: transparent;
}

.metersSection.addLine::after {
  content: "";
  border: 1px solid #e7e9ee;
  position: absolute;
  right: -30px;
  top: 20px;
  height: 100%;
}

/* // NEW DESIGN CSS */

.searchbar {
  -webkit-filter: drop-shadow(5px 5px 15px #efefef);
          filter: drop-shadow(5px 5px 15px #efefef);
}

.home-button {
  box-shadow: 5px 5px 30px 0px rgba(239, 239, 239, 1);

  box-shadow: 5px 5px 30px #efefef;
}

.searchbar {
  color: #7e7e7e;
}

.selection {
  color: #000;
}

.selection.meter {
  border: 2px solid #0ea4e7;
  border-radius: 8px;
}

.selection.site {
  border: 2px solid #64e480;
  border-radius: 8px;
}

.selection.time {
  border: 2px solid #808080;
  border-radius: 8px;
}

.selection .popover-btn {
  font-size: 12px;
}

.metercard.inside-searchbar {
  padding: 10px 0;
  /* width: auto !important; */
  height: auto !important;
  min-width: 300px;
  width: 30%;
  margin: 10px 10px;
}
.metercard.inside-searchbar p {
  max-height: 50px !important;
  overflow: hidden;
}

.tab.tab-active {
  border-bottom: 2px solid #0fa4e7;
  color: #0fa4e7;
}

.site-groups .img {
  text-align: center;
  padding: 10px 0;
}
.site-groups .count {
  background-color: #0da4e7;
}

.badge.kwh {
  background: rgba(14, 164, 231, 0.15);
  color: #0ea4e7;
}

.badge.steam {
  background: rgba(100, 228, 128, 0.15);
  color: #47d266;
}

.building {
  width: 31%;
}

.ring-4 {
  border: 2px solid #0da4e7;
}

.cursor-not-allowed > * {
  cursor: not-allowed;
}

input:checked ~ .Toolbar_dot__1RkV4 {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background-color: #ffffff;
}

input:checked + .Toolbar_toggle-container__1XQZ5 {
  background: #0ea4e7;
  box-shadow: 10px 10px 30px rgba(228, 228, 228, 0.5),
    10px 10px 30px rgba(228, 228, 228, 0.3);
}

.Toolbar_toggle-container__1XQZ5 {
  /* box-shadow: -2px -2px 7px 0px rgba(255, 255, 255, 1) inset;
  box-shadow: 2px 2px 7px 0px rgba(226, 233, 243, 1) inset; */
  background-color: #c4c4c4;
}

.Toolbar_dot__1RkV4 {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(228, 228, 228, 0.5),
    10px 10px 30px rgba(228, 228, 228, 0.3);
}

.Toolbar_toogle-text__hUIZL {
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
.metercard {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 20px;
  padding: 25px 0;

  height: 360px;
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.metercard .icon {
  background: #ffffff;
  /* box-shadow: 5px 5px 30px #efefef; */
  /* border-radius: 8px; */
  padding: 13px;
  margin-right: 15px;
}

.badge {
  padding: 8px 10px;
}

.metercard:not(.equipmentcard) > div:not(.chart) {
  padding: 0 25px;
}

.metercard .chart {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.metercard.equipmentcard .chart {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.metercard.equipmentcard {
  height: 462px;
  max-width: 457px;
  padding: 24px 10px;
}

.flip-out-hor-top {
  -webkit-animation: flip-out-hor-top 0.45s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:13:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:15:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:16:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.metercard button,
.equipmentcard button {
  background-color: #0da4e7;
}
.metercard:hover {
  box-shadow: 0px 10px 8px rgba(38, 38, 38, 0.2);

  -webkit-transform: translateY(-10px) scale(1.01);

          transform: translateY(-10px) scale(1.01);
  /* border: 1px solid #cccccc; */
  background-color: white;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-11-23 13:10:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.metercard:hover button {
  -webkit-animation: pulsate-fwd 1s infinite;
          animation: pulsate-fwd 1s infinite;
}

.maps-card {
  background: #ffffff;
  box-shadow: 5px 5px 15px rgba(239, 239, 239, 0.6);
  border-radius: 10px;
}

.map-marker {
  background-color: #f3f3f3;
  min-width: 230px;
  box-shadow: inset -10px -10px 30px rgb(246 246 246 / 80%),
    inset 10px 10px 30px rgb(237 237 237 / 80%);
  position: relative;
}
.map-marker::before {
  content: "";
  position: absolute;
  left: -16%;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(90deg);
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top: 30px solid #f3f3f3;
}

.map-marker .loc-icon {
  left: -18%;
  top: 38%;
}
.middle {
  left: 50%;
  top: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.map-wrapper {
  background: #ffffff;
  box-shadow: -20.337px -20.337px 20.337px #ffffff,
    10.1685px 10.1685px 20.337px rgba(228, 228, 228, 0.7),
    inset 10.1685px 10.1685px 20.337px rgba(243, 243, 243, 0.7),
    inset -10.1685px -10.1685px 20.337px rgba(255, 255, 255, 0.7);
  border-radius: 24.4044px;
}

.info .label {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.maps-card .info .icon {
  margin-right: 20px;
}
.maps-card.info-card {
  top: 23px;
  left: 23px;
  height: calc(100% - (23px + 23px));
}

.maps-card.weather-card {
  right: 23px;
  top: 23px;
  height: 200px;
  width: 300px;
}

.maps-card.weather-card h1 {
  font-size: 16px;
}

.weatherchart {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.maps-card.weather-card h1.weather-degree {
  font-size: 20.337px;
  line-height: 25px;
  letter-spacing: 0.02em;
}

.slide {
  text-align: center !important;
}
.slick-list {
  margin: 0 -7px !important;
}

.slick-slide > div {
  padding: 0 7px !important;
}
button.slick-arrow {
  z-index: 100;

  height: 100%;
}

button.slick-arrow.slick-prev {
  width: 100px;
}
button.slick-arrow.slick-next {
  right: -3%;
  width: 100px;
}
button.slick-arrow.slick-prev::after,
button.slick-arrow.slick-next::after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("https://static.thenounproject.com/png/1916331-200.png");
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  top: calc(50% - 50px);
  right: 22%;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 11px -11px 22px #d0d0d0, -11px 11px 22px #f0f0f0;
}

button.slick-arrow.slick-prev::after {
  background-image: url("https://static.thenounproject.com/png/1916329-200.png");
}

ul.slick-dots {
  bottom: -40px;
}
ul.slick-dots li {
  -webkit-transform: scale(2);
          transform: scale(2);
}
ul.slick-dots li::after {
  background-color: #fff !important;
}
ul.slick-dots li.slick-active {
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-slide {
  height: inherit !important;
}

.mapbox-row {
  /* height: 450px; */
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 13:46:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 13:50:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

.sidebar {
  position: sticky;
  width: 118px;
  background-color: #00aeef;
  padding-top: 165px;
}

.meters > div {
  box-shadow: inset 2px 2px 5px hsl(0deg 0% 83% / 80%);
}

.meters .dot {
  width: 10px;
  height: 10px !important;
  background-color: #ffba69;
  margin-right: 10px;
  border-radius: 100%;
}
.breakdown-chart {
  height: 500px;
}

.utility-card {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 16.9967px;
  height: 146.6px;
  margin: 0 10px;
}

.utility-cards .utility-card:first-child {
  margin-left: 0px;
}

.utility-cards .utility-card:last-child {
  margin-right: 0px;
}

.utility-card h1 {
  font-size: 32px;
  color: #666666;
}

.utility-card .icon-wrapper {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 174, 239, 0.05);
  border-radius: 12.7475px;
}

.utility-chart-wrapper {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 21.2458px;
  padding: 28px;
}

.offPeakBg {
  background-color: #ffba69;
}

.onPeakBg {
  background-color: #00aeef;
}

.utility-card.v2 p {
  color: #7e7e7e;
  font-size: 24px;
}
.utility-card.v2 {
  height: 168px;
  max-width: 90%;
}

.utility-card.v2 h1 {
  color: #383874;
  font-size: 55px;
  font-weight: 600;
}

.spacer-top {
  margin-top: 30px;
}

.saveDataBtn,
.nextMetricBtn {
  height: 56px;
  width: 186px;
  left: 1170px;
  color: #fff;
  background-color: #00aeef;
  font-size: 16px;
  border-radius: 12px;
  text-align: center;
}

.nextMetricBtn {
  background-color: #fff;
  border: 3px solid #00aeef;
  color: #00aeef;
  font-weight: bold;
}

.radio-btn {
  width: 22px;
  height: 22px;
  padding: 3px;
  border: 2px solid #00aeef;
}

.radio-btn .active-radio-btn {
  background-color: #00aeef;
}

.animating-layover {
  left: 100%;
  top: 50%;
  border-radius: 100%;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: all 0.5s ease-out;
}
.animating-layover.animate {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  top: 0;
  left: 0;
  border-radius: 0%;
}

.active_entry {
  box-shadow: 0px -2px 10px rgba(38, 38, 38, 0.2);

  -webkit-transform: translateY(-2px) scale(1.01);

          transform: translateY(-2px) scale(1.01);
  /* border: 1px solid #cccccc; */
  background-color: white;
}

.toggle {
  background: #efefef;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 50px;
  padding: 10xp 0;
  z-index: -2;
}

/* .toggle > div.cost {
  width: 35%;
}
.toggle > div.consumptions {
  width: 65%;
} */

.toggle .slider {
  height: 84%;
  top: 4px;
  left: 3px;
  border-radius: 10px;
  background-color: #fff;
  z-index: -1;
  transition: right left 0.5s;
}

.toggle .slider.right {
  left: unset;
  right: 3px !important;
}
.toggle .slider.left {
  right: unset;
  left: 3px !important;
}

.chartcanvas {
  height: 100%;
}

.chart-container {
  height: 400px;
  overflow: hidden;
}

/* .multi-charts {
  height: 250px;
  margin: 20px 0;
} */

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* Accordion styles */
.accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.accordion .tabs {
  border-radius: 8px;
  overflow: hidden;
}
.accordion .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.accordion .tab-label {
  display: flex;

  padding: 1em 0.5em;
  background: #fff;

  cursor: pointer;
  color: #000;
  /* Icon */
}
.accordion .tab-label:hover {
  background: #f9fafb;
}
.accordion .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  margin-left: auto;
}
.accordion .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.accordion .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.accordion .tab-close:hover {
  background: #f9fafb;
}
.accordion input:checked + .tab-label {
  background: #f9fafb;
}
.accordion input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.accordion input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.accordion .tab.command .tab-label:hover,
.accordion input:checked + .tab-label.label-command {
  background-color: #f8d5e7;
}
.accordion .tab.setpoint .tab-label:hover,
.accordion input:checked + .tab-label.label-setpoint {
  background-color: #d5e8e3;
}
.accordion .tab.sensor .tab-label:hover,
.accordion input:checked + .tab-label.label-sensor {
  background-color: #d3e6f9;
}

.sensor-badge {
  background-color: #d3e6f9;
}
.command-badge {
  background-color: #f8d5e7;
}
.setpoint-badge {
  background-color: #d5e8e3;
}

.building-list li:nth-child(2) {
  border-top: none;
}

.panelToggleButtons {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.panelToggleButtons.tab-active {
  border-bottom: 2px solid #383874;
  color: #383874;
}

.details-box {
  background: #ffffff;
  border: 1px solid #e2e9ea;
  border-radius: 10px;
}
.details-box h3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.15em;
}

:root {
  --primary-text-color: #383874;
  --secondary-text-color: rgba(87, 87, 137, 0.6);
}

.primary-text-color {
  color: #383874;
  color: var(--primary-text-color);
}

.secondary-text-color {
  color: rgba(87, 87, 137, 0.6);
  color: var(--secondary-text-color);
}

.primary-bg-color {
  background-color: #383874;
  background-color: var(--primary-text-color);
}
.secondary-bg-color {
  background-color: rgba(87, 87, 137, 0.6);
  background-color: var(--secondary-text-color);
}

html {
  font-family: "Montserrat", "sans-serif";
}

body {
  font-family: inherit !important;
  background-color: #f9fafd;
  /* background-color: var(--primary-text-color); */
}
* {
  box-sizing: border-box;
  font-family: inherit;
}

.neu-icon {
  box-shadow: -18px -18px 30px #ffffff, 18px 18px 30px #dbdbdb,
    inset 18px 18px 30px #f3f3f3, inset -18px -18px 30px #ffffff;
}
.neu-box {
  box-shadow: inset -10px -10px 30px rgb(246 246 246 / 80%),
    inset 10px 10px 30px rgb(237 237 237 / 80%);
}
.neu-icon-in {
  box-shadow: inset 2px 2px 7px #e2e9f3, inset -2px -2px 7px #fff;
}

.bg-blue {
  background-color: #0ea4e7;
}
.dropdown-min-width {
  min-width: 150px;
}

.fade-in-bck {
  -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 14:5:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

