input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #ffffff;
}

input:checked + .toggle-container {
  background: #0ea4e7;
  box-shadow: 10px 10px 30px rgba(228, 228, 228, 0.5),
    10px 10px 30px rgba(228, 228, 228, 0.3);
}

.toggle-container {
  /* box-shadow: -2px -2px 7px 0px rgba(255, 255, 255, 1) inset;
  box-shadow: 2px 2px 7px 0px rgba(226, 233, 243, 1) inset; */
  background-color: #c4c4c4;
}

.dot {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(228, 228, 228, 0.5),
    10px 10px 30px rgba(228, 228, 228, 0.3);
}

.toogle-text {
}
